<template>
  <v-card class='mb-5 form-container py-5' :loading="loading" elevation="0" color="transparent">
    <h2 class='create-header mb-5 text-center'>
      <span v-if="!isEmployer">🤩 Let's create your Profile!</span>
      <span v-else>😊 create your Profile!</span>
    </h2>
    <v-form ref='createProfile' v-model='valid' @submit.prevent='validate()' id='create-education-form' class='v-text-field--rounded form'>
      <v-row>
        <v-col :cols="12" :sm="6">
          <v-text-field
            ref='first_name'
            outlined
            :loading="loading"
            value="something"
            :rules="required"
            required
            label='First Name'
            v-model='currentUser.meta.first_name' />
        </v-col>
        <v-col :cols="12" :sm="6">
          <v-text-field
            ref='last_name'
            outlined
            :loading="loading"
            :rules="required"
            label='Last Name'
            v-model='currentUser.meta.last_name' />
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <v-radio-group v-model="currentUser.meta.gender" row class="mt-0">
            <v-radio label="Female" value="female"></v-radio>
            <v-radio label="Male" value="male"></v-radio>
          </v-radio-group>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <v-autocomplete
            ref='location'
            v-model="currentUser.meta.location"
            :search-input.sync="locationInput"
            @input="locationInput = null"
            auto-select-first
            :items="items"
            :rules="required"
            :loading="loading"
            outlined
            hide-no-data
            item-text="Description"
            clearable
            persistent-hint
            hint="People in your city can find you quickly 🔥"
            label="Location"
            placeholder="Start typing to fetch your location"
            prepend-inner-icon="mdi-map-marker"
            :menu-props="autocompleteMenuProps" />
        </v-col>
      </v-row>
      <actions :loading="loading" :valid="valid" :is-employer="isEmployer" />
      <v-alert text outlined color='error' icon='mdi-fire' v-if='error' class='mt-5'>
        {{ error }}
      </v-alert>
    </v-form>
  </v-card>
</template>

<script>
import Actions from '../../../components/Onboarding/Actions'
import autocomplete from '../../../mixins/autocomplete'
import mapsMixin from '../../../mixins/mapsMixin'
const UPDATE_USER = require('../../../graphql/updateUser.graphql')

export default {
  props: {
    currentUser: {
      type: Object,
      default: () => {}
    }
  },
  data () {
    return {
      valid: true,
      loading: false,
      required: [v => (!!v) || 'Field is required'],
      error: null,
      items: this.currentUser.meta.location ? [this.currentUser.meta.location] : []
    }
  },
  computed: {
    isEmployer () {
      return this.currentUser.role === 'employer'
    }
  },
  methods: {
    validate () {
      if (this.$refs.createProfile.validate()) {
        this.submitUser()
      }
    },
    async submitUser () {
      this.loading = true
      const onboardingDone = this.isEmployer
      try {
        await this.$apollo.mutate({
          mutation: UPDATE_USER,
          variables: {
            data: {
              first_name: this.currentUser.meta.first_name,
              last_name: this.currentUser.meta.last_name,
              location: this.currentUser.meta.location,
              gender: this.currentUser.meta.gender,
              onboarding_done: onboardingDone
            }
          }
        })
        await this.$emit('reFetch')
        if (this.isEmployer) {
          await this.$router.push({ name: 'feed' })
        } else {
          await this.$router.push({ name: 'educationDetails' })
        }
      } catch (e) {
        console.log(e)
        this.error = e
      } finally {
        this.loading = false
      }
    }
  },
  components: {
    Actions
  },
  mixins: [autocomplete, mapsMixin]
}
</script>
<style lang='scss' scoped>
  .form-container {
    border-radius: 4px;
    .form {
      padding: 0 1rem 0;
    }
  }
</style>
